var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"formcontainer staticpage"},[(_vm.news)?_c('div',{staticClass:"formbox"},[_c('h2',[_vm._v("Duyurular")]),(_vm.loaded)?_c('div',{staticClass:"duyuruline descline"},_vm._l((_vm.news),function(item,index){return _c('div',{key:index,staticClass:"duyurucard"},[(item.thumb)?_c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":item.thumb.src,"alt":""}})]):_vm._e(),_c('div',{staticClass:"duyurucontent"},[(item.title)?_c('h3',{staticClass:"title",domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e(),(item.date)?_c('div',{staticClass:"date",domProps:{"innerHTML":_vm._s(item.date)}}):_vm._e(),(item.desc)?_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(item.desc.join(' '))}}):_vm._e(),(item.video)?_c('div',{staticClass:"video"},[_c('iframe',{attrs:{"width":"100%","height":item.video.height || 315,"src":item.video.src,"title":item.video.title || '',"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e(),(item.gallery && !item.imageNotClickable)?_c('div',{staticClass:"gallery",class:{
                'single-img': _vm.imgCount(item) == 1,
                'double-img': _vm.imgCount(item) == 2,
                'triple-img': _vm.imgCount(item) > 2,
              }},_vm._l((item.gallery),function(img,index2){return _c('img',{directives:[{name:"img",rawName:"v-img",value:({ group: index, thumbnails: true }),expression:"{ group: index, thumbnails: true }"}],key:index2,attrs:{"src":img.src,"alt":""},on:{"click":function($event){return _vm.imgClick({ item, src: img.src })}}})}),0):_vm._e(),(item.gallery && item.imageNotClickable)?_c('div',{staticClass:"gallery",class:{
                'single-img': _vm.imgCount(item) == 1,
                'double-img': _vm.imgCount(item) == 2,
                'triple-img': _vm.imgCount(item) > 2,
              }},_vm._l((item.gallery),function(img,index2){return _c('img',{key:index2,attrs:{"src":img.src,"alt":""},on:{"click":function($event){return _vm.imgClick({ item, src: img.src })}}})}),0):_vm._e(),_c('div',{staticClass:"duyurufooter"},[(item.link)?_c('div',{staticClass:"duyurulink"},[_c('a',{attrs:{"href":item.link.href,"target":"_blank"}},[_vm._v(_vm._s(item.link.desc || "Detaylı bilgi >"))])]):_vm._e()])])])}),0):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }